
import Header from "components/home/Header"
import Navbar from "components/navigation/Navbar"
import Services from "components/home/Services"
import Layout from "hocs/layouts/Layout"
import Contact from "components/home/Contact"
import Footer from "components/navigation/Footer"
import LiveChat from "components/home/LiveChat"

function Home() {
 


    return (
        <>

            <Layout>
             
                <Header></Header>
                <LiveChat></LiveChat>
                <Services></Services>
                <Contact></Contact>
                <Footer></Footer>
            </Layout>
        </>
    )
}
export default Home