import {connect} from "react-redux"
import { check_authenticated, load_user, logout, refresh } from 'redux/actions/auth/auth'
import { useEffect } from 'react'


function Layout ({
    
    children,
    refresh,
    check_authenticated,
    load_user,
    user_loading,
    isAuthenticated,
    user,
    logout



}){
    
    useEffect(()=>{

        refresh()
        check_authenticated()
        load_user()
    },[])

    return(
        <div>
            {children}
        </div>
    )

}

const mapStateToProp = state =>  ({ //llama las variables de redux que quiero llamar
    user_loading: state.auth.user_loading,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,

})
export default connect(mapStateToProp,{
    refresh,
    check_authenticated,
    load_user,
    logout


})(Layout)