export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAIL = 'GET_CONTACT_FAIL';
export const GET_RESUME_SUCCESS = 'GET_RESUME_SUCCESS';
export const GET_RESUME_FAIL = 'GET_RESUME_FAIL';
export const GET_OPPORTUNITIES_SUCCESS = 'GET_OPPORTUNITIES_SUCCESS';
export const GET_OPPORTUNITIES_FAIL = 'GET_OPPORTUNITIES_FAIL';
export const GET_INFO_SUCCESS = 'GET_INFO_SUCCESS';
export const GET_INFO_FAIL = 'GET_INFO_FAIL';
export const GET_FILTER_SUCCESS = 'GET_FILTER_SUCCESS';
export const GET_FILTER_FAIL = 'GET_FILTER_FAIL';
export const GET_SEARCH_BLOG_SUCCESS = 'GET_SEARCH_BLOG_SUCCESS';
export const GET_SEARCH_BLOG_FAIL = 'GET_SEARCH_BLOG_FAIL';




export const GET_VIEWS_SUCCESS = 'GET_VIEWS_SUCCESS';
export const GET_VIEWS_FAIL = 'GET_VIEWS_FAIL';