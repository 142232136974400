

import LeadsList from "components/dashboard/LeadsList"
import HeadDash from "components/navigation/HeadDash"
import Slide from "components/navigation/Slide"
import Layout from "hocs/layouts/Layout"
import { useEffect } from 'react'
import { connect } from "react-redux";
import { get_contacts } from "redux/actions/contacts/contacts";
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";


function Leads({
    get_contacts, contacts, count, views,

    login,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
    user

}) {


    useEffect(() => {
        window.scrollTo(0, 0)
        get_contacts()
        isAuthenticated ? <> </> :
            <>
                {refresh()}
                {check_authenticated()}
                {load_user()}
            </>


    }, [])




    return (

        <>

            <Layout>

                {user ? <>
                    <HeadDash user={user && user} />
                    {contacts ? <>
                        <LeadsList contacts={contacts && contacts} />

                    </> :
                        <>loading</>
                    }
                </> :
                    <>loading</>
                }

                <Slide />

            </Layout>
        </>
    )
}
const mapStateToProps = state => ({
    contacts: state.contacts.contacts,
    count: state.contacts.count,
    views: state.views.views,
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user

})
export default connect(mapStateToProps, {
    get_contacts,
    login,
    refresh,
    check_authenticated,
    load_user


})(Leads)