import digioNav from "assets/img/digioNav.png"
import { connect } from "react-redux"
import { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/navbar.css'

function NavigationBar() {

  return (
    <>

      <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
        <Container>

          <Navbar.Brand href="#home">
            <img
              src={digioNav}
              width={180}
              height={54}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
         
        </Container>
      </Navbar>
    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(NavigationBar)