
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/style.css'
import { faIdCard, faStar, faCheckSquare, faEye } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { get_filter } from "redux/actions/contacts/contacts";
import Button from 'react-bootstrap/Button';
import { useState } from "react";
import Form from 'react-bootstrap/Form';




function Index({ opportunities, count, views, resume, information, get_filter, filtered, oportunidades , informacion, total }) {


    const [filtere, setFiltere] = useState(false);
    const [formData, setFormData] = useState({
        month: '',
        year: '',
    });

    const { month,
        year } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {

        e.preventDefault();
        let formData = new FormData()
        formData.append('month', month)
        formData.append('year', year)
        
        let fecha=formData.get('year') +"-"+ formData.get('month')
       
        get_filter(fecha); 
        setFiltere(true);

    }

    return (
        <>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Dashboard</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ol>
                    </nav>
                </div>
                <div className="search-bar">
                    <Form onSubmit={e => onSubmit(e)} >
                        <Form.Group className="w-50 ">
                            <Form.Select required className="d-inline" style={{ width: "60%" }} name="month" onChange={e => onChange(e)} value={month}>
                                <option value="" disabled >Mes</option>
                                <option value="01" >Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06" >Junio</option>
                                <option value="07" >Julio</option>
                                <option value="08" >Agosto</option>
                                <option value="09" >Septiembre</option>
                                <option value="10" >Octubre</option>
                                <option value="11" >Noviembre</option>
                                <option value="12" >Diciembre</option>
                            </Form.Select>
                            <Form.Select required className="d-inline my-auto" style={{ width: "40%" }} name="year" onChange={e => onChange(e)} value={year}>
                                <option value="" disabled >Año</option>
                                <option value="2023" >2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028" >2028</option>
                                <option value="2029" >2029</option>
                                <option value="2030" >2030</option>
                                <option value="2031" >2031</option>
                                <option value="2032" >2032</option>

                            </Form.Select>

                        </Form.Group>
                        <Button className="my-2 m d-inline" type="submit">Filtrar</Button>
                    </Form>
                </div>

                <section className="section dashboard">
                    <div className="row">


                        <div className="col-12">
                            <div className="row">


                                <div className="  col-md-4">
                                    <div className="card info-card sales-card">

                                        <div className="filter">

                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>

                                        <div className="card-body">
                                            <h5 className="card-title">Leads <span>| {count}</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <FontAwesomeIcon icon={faIdCard} />
                                                </div>
                                                <div className="ps-3">

                                                {total? <><h6>{total.total} <span style={{ color: "#899bbd"}} >| Mes</span></h6></>: <></>}
                                                    

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card info-card revenue-card">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>

                                        <div className="card-body">
                                            <h5 className="card-title">Oportunidades <span>| {opportunities}</span>  </h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <FontAwesomeIcon icon={faStar} />
                                                </div>
                                                <div className="ps-3">
                                                    

                                                    {oportunidades? <><h6>{oportunidades.oportunidades} <span style={{ color: "#899bbd"}}>| Mes</span> </h6></>: <></>}
                                                    

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-4">

                                    <div className="card info-card customers-card">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>

                                        <div className="card-body">
                                            <h5 className="card-title">En formación <span>| {information}</span></h5>

                                            <div className="d-flex align-items-center">
                                                <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <FontAwesomeIcon icon={faCheckSquare} />

                                                </div>
                                                <div className="ps-3">
                                                    {informacion? <><h6>{informacion.informacion} <span style={{ color: "#899bbd"}}>| Mes</span> </h6></>: <></>}

                                                    

                                                </div>
                                            </div>

                                        </div>
                                   
                                    </div>

                                </div>
                               

                            

                                <div className="col-12">
                                    <div className="card">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>

                                        <div className="card-body">
                                            <h5 className="card-title">Leads recientes </h5>

                                            <table className="table table-borderless datatable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">id</th>
                                                        <th scope="col">Nombre</th>
                                                        <th scope="col">Correo</th>
                                                        <th scope="col">Telefono</th>
                                                        <th scope="col">Interés</th>
                                                        <th scope="col">Estado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        resume && resume.map((contact, index) => (
                                                            <tr >
                                                                <td>{contact.id} </td>
                                                                <td>{contact.name} </td>
                                                                <td>{contact.email} </td>
                                                                <td>{contact.phone} </td>
                                                                <td>{contact.interest} </td>
                                                                <td>{contact.status == "Oportunidad" ? <> <span className="badge bg-success">Oportunidad</span></> : <></>}
                                                                    {contact.status == "En formacion" ? <> <span className="badge bg-warning">En formación</span></> : <></>}
                                                                    {contact.status == "Sin estado" ? <> <span className="badge bg-secondary">Sin estado</span></> : <></>}</td>
                                                            </tr>
                                                        ))
                                                    }




                                                </tbody>
                                            </table>

                                        </div>

                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card top-selling overflow-auto">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                      

                    </div>
                </section>
            </main>



        </>
    )
}
const mapStateToProps = state => ({
    filtered: state.contacts.filter,
    oportunidades: state.contacts.filter,
    informacion : state.contacts.filter,
    total: state.contacts.filter


})
export default connect(mapStateToProps, {
    get_filter

})(Index)  