import footer from "assets/img/footer.png"
import goyn from "assets/img/goyn.png"
import fcorona from "assets/img/fcorona.png"
import qsd from "assets/img/qsd.png"
import digioLogo from "assets/img/digioLogo.png"
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'



//import 'styles/footer.css'

function Footer() {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 768);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            <div className="container-flex" style={{ backgroundColor: "#936BE1" }}></div>

            <div className="footer-color" style={{
                backgroundImage: `url(${footer})`, height: ` ${isSmallScreen ? "100vh" : "50vh"} `,
                marginTop: '-100px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }}>

                <div className="container text-center px-5 mt-5 container-footer" style={{ paddingTop: "210px" }}>
                    <div className="row my-3">
            

                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto  my-auto">
                            <img src={digioLogo} alt="digio" className="img-fluid " style={{ width: "240px" , marginBottom:  ` ${isSmallScreen ? "30px" : ""} ` }} />


                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto  my-auto">
                            <img src={goyn} alt="goyn" className="img-fluid" style={{ width: "200%" , marginBottom:  ` ${isSmallScreen ? "20px" : ""} `  }} />


                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto my-auto">
                            <img src={qsd} alt="qsd" className="img-fluid" style={{ width: "200%", marginBottom:  ` ${isSmallScreen ? "15px" : ""} `  }} />


                        </div>

                        <div className={` ${isSmallScreen ? "col-md-4 col-lg-3 col-xl-3 mx-auto mt-5 " : "col-md-4 col-lg-3 col-xl-3 mx-auto my-auto "} `}>
                            <img src={fcorona} alt="fcorona" className="img-fluid mb-2 " style={{ width: "200%" , marginBottom:  ` ${isSmallScreen ? "20px" : ""} ` }} />
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Footer)  