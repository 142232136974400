import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,
    GET_RESUME_SUCCESS,
    GET_RESUME_FAIL,
    GET_OPPORTUNITIES_SUCCESS,
    GET_OPPORTUNITIES_FAIL,
    GET_INFO_SUCCESS,
    GET_INFO_FAIL,
    GET_FILTER_SUCCESS,
    GET_FILTER_FAIL,
    GET_SEARCH_BLOG_FAIL,
    GET_SEARCH_BLOG_SUCCESS,
    GET_VIEWS_SUCCESS,
    GET_VIEWS_FAIL
} from '../actions/contacts/types'

const initialState = {
    contacts: null
}

export default function contacts(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                count: payload.count,
                next: payload.next,
                previous: payload.previous,
                contacts: payload.results.contacts
            }
        case GET_CATEGORIES_FAIL:
            return {
                ...state,
                contacts: null,
                count: null,
                next: null,
                previous: null,
            }
        case GET_SEARCH_BLOG_SUCCESS:
            return {
                ...state,
                filtered_posts: payload.results.filtered_posts,
                count: payload.count,
                next: payload.next,
                previous: payload.previous,
            }
        case GET_SEARCH_BLOG_FAIL:
            return {
                ...state,
                filtered_posts: null,
                count: null,
                next: null,
                previous: null,
            }   
        case GET_VIEWS_SUCCESS:
            return {
                ...state,
                countViews:payload.count
            }   
        case GET_VIEWS_FAIL:
            return {
                ...state,
                count: null
            }   
        case GET_CONTACT_SUCCESS:
            return {
                ...state,
                contact:payload.post
            }   
        case GET_CONTACT_FAIL:
            return {
                ...state,
                contact: null
            }
        case GET_RESUME_SUCCESS:
            return {
                ...state,
                resume:payload.results.resumen
            }   
        case GET_RESUME_FAIL:
            return {
                ...state,
                resume: null
            }   
        case GET_OPPORTUNITIES_SUCCESS:
            return {
                ...state,
                opportunities:payload.count
            }   
        case GET_OPPORTUNITIES_FAIL:
            return {
                ...state,
                opportunities: null
            }   
        case GET_INFO_SUCCESS:
            return {
                ...state,
                info:payload.count
            }   
        case GET_INFO_FAIL:
            return {
                ...state,
                info: null
            }   
        case GET_FILTER_SUCCESS:
            return {
                ...state,
                filter:payload
            }   
        case GET_FILTER_FAIL:
            return {
                ...state,
                filter: null
            }   
        default:
            return state
    }
}