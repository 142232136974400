import { connect } from "react-redux"


function Card({ svgicon, heading, detail }) {

    return (

        <>

            <div className="book mx-auto">
                <p className='text'>{detail}</p>
                <div className="cover">
                    <p className='texth'>  <img
                                src={svgicon}
                                className=" svgicon img-fluid "
                                alt={svgicon}
                                width= "50%"

                            /> <br></br> <br></br>{heading}</p>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Card)