

import LeadsList from "components/dashboard/LeadsList"
import HeadDash from "components/navigation/HeadDash"
import Slide from "components/navigation/Slide"
import Layout from "hocs/layouts/Layout"
import { useEffect } from 'react'
import { connect } from "react-redux";
import { get_contact, get_contacts } from "redux/actions/contacts/contacts";
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import { useParams } from "react-router-dom"
import Edit from "components/dashboard/Edit"



function EditLead({
    get_contacts, contacts, count, views,

    login,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
    user,
    get_contact,
    contact

}) {

    const params = useParams()
    const id = params.id

    useEffect(() => {
        window.scrollTo(0, 0)
        get_contact(id)
        get_contacts()
        isAuthenticated ? <> </>:
        <>
        {refresh()}
        {check_authenticated()}
        {load_user()}
        </>
    }, [])

    
  





    return (

        <>

            <Layout>
            {user ? <>
                <HeadDash user={user&&user}/>
            

                </>:
                <>loading</>
            }
                <Slide />

                {contact ? <>
                    <Edit contact={contact && contact} />
                
                </>:

                <>loading</>
               
            }
                

            </Layout>
        </>
    )
}
const mapStateToProps = state => ({
    contact: state.contacts.contact,
    user: state.auth.user

})
export default connect(mapStateToProps, {
    get_contacts,
    login,
    refresh,
    check_authenticated,
    load_user,
    get_contact


})(EditLead)