import { useState, useEffect } from "react";
import { connect } from "react-redux"
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import 'styles/contact.css'
import Wave from 'react-wavify'
import { Link } from "react-router-dom";
import tiktok from "assets/img/tik-tok.png"
import instagram from "assets/img/instagram.png"
import facebook from "assets/img/facebook.png"
import succes from "assets/img/success.png"
import HashLoader from "react-spinners/HashLoader"

import { Modal } from 'react-bootstrap';


//motiondigio
import { Canvas } from "@react-three/fiber"
import { OrbitControls, Sphere, MeshDistortMaterial } from "@react-three/drei";
import digio from "assets/img/digio.png"
import creadora from "assets/img/creadora.png"


function Contacto() {

    const [loading, setLoading] = useState(false);

    let contentStyle = { };
    const [showModal, setShowModal] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 768);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (isSmallScreen){
        contentStyle = { width: "80%" , fontSize : "12px"   };

    }
    else {
        contentStyle = { width: "50%" };


    }

    const [agreed, setAgreed] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        interest: '',
        gender: '',
    });

    const { name,
        email,
        phone,
        interest,
        countryCode,
        gender } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        // Cambia el estado de carga a true al comenzar el envío del formulario
        

        e.preventDefault();

        if (agreed) {
            setLoading(true);

            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            let formData = new FormData()
            formData.append('name', name)
            formData.append('email', email)
            formData.append('phone', phone)
            formData.append('interest', interest)
            formData.append('countryCode', countryCode)
            formData.append('gender', gender)
            



            const fetchData = async () => {
                try {
                  const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/contacts/`,
                    formData,
                    config
                  );
                  setShowModal(true);
                } catch (error) {
                  if (error.response && error.response.status === 404) {
                    alert(
                      'Error al enviar datos, es posible que ya te encuentres registrado'
                    );
                  } else {
                    // Lógica para otros errores
                  }
                } finally {
                  setLoading(false); // Asegura que setLoading se ejecute incluso si hay un error
                }
              };
            fetchData()

            
        } else {
            alert("Debes aceptar los terminos y politica de privacidad")
        }
    }



    return (

        <div className="container-flex" style={{ marginTop: "-60px" }}>


            <Wave fill='#fff'
                paused={false}
                options={{
                    height: 20,
                    amplitude: 20,
                    speed: 0.15,
                    points: 3
                }}
            />
            <Container>

                <div className="row ">
                    <div className="col-12 col-md-6  text-center container-flex align-items-center">



                        <div className="position-relative">
                            <img
                                src={digio}
                                className=" digio img-fluid "
                                alt="digio"

                            />

                        </div>

                        <div className="position-relative">
                            <img
                                src={creadora}
                                className=" creadora img-fluid "
                                alt="digio"
                            />

                        </div>


                        <Canvas camera={{ fov: 25, position: [5, 5, 5] }} >
                            <OrbitControls enableZoom={false}></OrbitControls>
                            <ambientLight intensity={1} />
                            <directionalLight position={[3, 2, 1]}></directionalLight>
                            <Sphere args={[1, 100, 200]} scale={1.5}>
                                <MeshDistortMaterial
                                    color="#5E17EB"
                                    attach="material"
                                    distort={0.5}
                                    speed={2}
                                ></MeshDistortMaterial>
                            </Sphere>
                        </Canvas>




                    </div>



                    <div className="col-12 col-md-6 ">
                        <section id="contact">
                            <Form onSubmit={e => onSubmit(e)} method="POST" className="mt-3">
                                <h3 className="text-center pb-4" style={{ color: "#5E17EB", fontSize: " 40px" }}>¡Inscríbete y aprende!</h3>

                                <Form.Group className="mb-3 mt-3">
                                    <Form.Label className="fw-bold d-block" style={{ color: "#5E17EB" }}>Nombre Completo</Form.Label>
                                    <Form.Control className="d-inline" value={name} name="name" onChange={e => onChange(e)} placeholder="Nombre" style={{ width: "70%" }} required />

                                    <Form.Select required name="gender" className="d-inline" style={{ width: "30%" }} value={gender} onChange={e => onChange(e)}>
                                        <option value="" disabled selected >Género</option>
                                        <option value="Hombre">Hombre
                                        </option>
                                        <option value="Mujer">Mujer</option>
                                        <option value="Intersexual">Intersexual</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold" style={{ color: "#5E17EB" }}>Email</Form.Label>
                                    <Form.Control type="email" value={email} name="email" onChange={e => onChange(e)} placeholder="ejemplo@ejemplo.com" required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold d-block" style={{ color: "#5E17EB" }}>Ingresa tu número de celular y obtén orientación gratuita</Form.Label>
                                    <Form.Select required name="countryCode" className="d-inline" style={{ width: "30%" }} value={countryCode} onChange={e => onChange(e)}>
                                        <option value="" disabled selected >Ciudad (+57)</option>
                                        <option value="Bogotá">Bogotá</option>
                                        <option value="Medellín">Medellín</option>
                                        <option value="Cartagena de indias">Cartagena de indias</option>
                                        <option value="Bucaramanga">Bucaramanga</option>
                                        <option value="Santa Marta">Santa Marta</option>
                                        <option value="Villavicencio">Villavicencio</option>
                                        <option value="Pereira">Pereira</option>
                                        <option value="Manizales">Manizales</option>
                                        <option value="Cali">Cali</option>
                                        <option value="Barranquilla">Barranquilla</option>
                                        <option value="Cúcuta">Cúcuta</option>
                                        <option value="Ibagué">Ibagué</option>
                                        <option value="Pasto">Pasto</option>
                                        <option value="Neiva">Neiva</option>
                                        <option value="Montería">Montería</option>
                                    </Form.Select>
                                    <Form.Control type="int" className="d-inline" style={{ width: "70%" }} value={phone} name="phone" onChange={e => onChange(e)} placeholder="123456789" maxLength="10" minLength="10" required />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className="fw-bold" style={{ color: "#5E17EB" }}>Interés</Form.Label>

                                    <Form.Select name="interest" className=" " value={interest} onChange={e => onChange(e)} required>
                                        <option value="" disabled >Interés</option>
                                        <option value="1">No sé qué estudiar, qué me recomiendas</option>
                                        <option value="2">¿Cuáles son las carreras digitales?</option>
                                        <option value="3">Programación</option>
                                        <option value="4">Marketing Digital</option>
                                        <option value="5" >Ciencia de datos</option>
                                        <option value="6" >Diseño y creación de contenido digital</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3">

                                    <div className="d-inline-block">
                                        <Form.Check onChange={setAgreed} value={agreed} type="checkbox" label=" " required ></Form.Check >
                                    </div>
                                    <div className="d-inline-block">
                                        Acepto
                                        <Popup trigger={<Link>  <span className="fw-bold" style={{ color: "#5E17EB" }}>  términos y condiciones* </span> </Link>}   {...{ contentStyle }}
                                            modal
                                            nested  >  <p>  Al diligenciar la presente encuesta, autorizo a FUNDACIÓN CORONA, operador del Programa
                                            Global Opportunity Youth Network (GOYN) para que recolecte, almacene, utilice, analice, y
                                            actualice mi información de contacto y los datos aquí recogidos, con el fin de: (i) Almacenarla en las
                                            bases de datos de Fundación Corona y del Programa; (ii) Informarme sobre el desarrollo de
                                            actividades, convocatorias y eventos que realice la fundación y/o el Programa, así como sus socios
                                            y aliados; (iii) Realizar seguimiento al desarrollo de la iniciativa; (iv) Generar informes de gestión; (v)
                                            Realizar seguimiento a los indicadores de resultados; (vi) Enviar información considerada de mi
                                            interés relacionada con el programa, o con otros programas de la fundación, por correo electrónico,
                                            medios físicos, vía mensajes de texto (SMS y/o MMS); (vi) Compartirla con terceros encargados del
                                            tratamiento contratados para el ejercicio de estas mismas finalidades durante el desarrollo del
                                            programa; (vii) Responder tanto mis consultas y/o reclamos, como los requerimientos legales de
                                            entidades administrativas y judiciales.
                                            Conozco que, en todo caso, el tratamiento de mis datos personales estará sujeto a lo establecido en la Ley 1581 de 2012, sus decretos reglamentarios y las normas que los modifiquen, así como a la política de privacidad publicada en http://fundacioncorona.org/es/sobre-nosotros/tratamiento-de-datos, la cual contiene mis derechos, lineamientos, directrices y procedimientos sobre el tratamiento                                            realizado por Fundación Corona y el programa GOYN, que declaro conocer, y que, para acceder,
                                            actualizar, rectificar o solicitar la supresión de los datos personales recopilados, podré comunicarme
                                            al correo electrónico tratamientodatospersonales@fcorona.org, o por escrito a la calle 70 No. 7-30,
                                            oficina 1001 en Bogotá. </p></Popup>
                                    </div>
                                </Form.Group>
                                <div class=" text-center">

                                    <Button onClick={() => setShowModal(false)} className={`mx-auto ${loading ? 'btn-no-color' : ''}`} type="submit" disabled={loading}>{loading ?
                                    <HashLoader  color="#5E17EB" />  : 'Enviar'}</Button>


                                    <div>
                                        {/* Resto del contenido de tu componente */}

                                        {/* Modal */}
                                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                                            <div className="container py-4 text-center">
                                                <img
                                                    src={succes}
                                                    width={150}
                                                    height={125}
                                                    className="d-inline-block align-top"
                                                    alt="React Bootstrap logo"
                                                />
                                                <p className="text-center mx-auto my-2"> ¡Gracias por registrarte! Nos contactaremos contigo lo más pronto posible. </p>
                                                <p className="text-center mx-auto my-2">  Visita nuestras redes.</p>
                                                <Link className="mx-2" target="blank" to="https://www.instagram.com/digio_co/"> <img
                                                    src={instagram}
                                                    width={50}
                                                    height={45}
                                                    className="d-inline-block align-top"
                                                    alt="React Bootstrap logo"
                                                />
                                                </Link>
                                                <Link className="mx-2" target="blank" to="https://www.facebook.com/DigioDG"  >
                                                    <img
                                                        src={facebook}
                                                        width={50}
                                                        height={45}
                                                        className="d-inline-block align-top"
                                                        alt="React Bootstrap logo"
                                                    />
                                                </Link>

                                                <Link className="mx-2" target="blank" to="https://www.tiktok.com/@digio_co?lang=es">
                                                    <img
                                                        src={tiktok}
                                                        width={50}
                                                        height={45}
                                                        className="d-inline-block align-top"
                                                        alt="React Bootstrap logo"
                                                    />
                                                </Link>


                                            </div>
                                        </Modal>
                                    </div>



                                </div>

                            </Form>
                        </section>
                    </div>
                </div>
            </Container >


        </div >
    )
}



const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Contacto)