
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/style.css'
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useParams } from "react-router-dom"
import Popup from 'reactjs-popup';


function Edit({ contact }) {

    const params = useParams()
    const id = params.id

    const [formData, setFormData] = useState({
        status: '',
        id: id,

    });

    const {status } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {

        e.preventDefault();



        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'Authorization': `JWT ${localStorage.getItem('access')}`
            }
        };

        let formData = new FormData()
        formData.append('status', status)
        formData.append('id', id)



        const fetchData = async () => {
            axios.put(`${process.env.REACT_APP_API_URL}/api/contacts/edit`, formData, config).then(res => {

                console.log("editado correctamente")

            }).catch(err => {

                console.log("Error al editar")
            })

        }
        fetchData()


    }





    return (
        <>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Leads Control</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                            <li className="breadcrumb-item ">Leads Control</li>
                            <li className="breadcrumb-item active">Editar estado</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">


                        <div className="container">


                            <div className="row">

                                <div className="col-12">
                                    <div className="card">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">



                                        <div className="card-body">

                                            <table className="table table-borderless my-2 datatable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">id</th>
                                                        <th scope="col">Nombre</th>
                                                        <th scope="col">Correo</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Interés</th>
                                                        <th scope="col">Fecha</th>
                                                        <th scope="col">Estado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td scope="col">{contact.id}</td>
                                                        <td scope="col">{contact.name}</td>
                                                        <td scope="col">{contact.email}</td>
                                                        <td scope="col">+57 {contact.phone}</td>
                                                        <td scope="col">{contact.interest}</td>
                                                        <td scope="col">{contact.contact_date}</td>
                                                        <td scope="col">{contact.status}</td>
                                                    </tr>



                                                </tbody>
                                            </table>

                                        </div>
                                        <div className="col-12">
                                            <div className="card top-selling overflow-auto">

                                                <Form onSubmit={e => onSubmit(e)} >
                                                    <h3 className="text-center my-4  " style={{ color: "#5E17EB", fontSize: " 25px" }}>Actualizar el estado de {contact.name}</h3>



                                                    <Form.Group className="mb-3">

                                                        <Form.Select onChange={e => onChange(e)} name="status" className=" w-50 text-center mx-auto " >
                                                            <option value="Sin estado" disabled selected >Interés</option>
                                                            <option value="Oportunidad">Oportunidad</option>
                                                            <option value="En formacion">En formación</option>

                                                        </Form.Select>
                                                    </Form.Group>


                                                    <Popup trigger={<div className=" text-center">
                                                        <Button className="mx-auto" type="submit">Actualizar</Button>
                                                    </div>}
                                                        modal
                                                        nested>

                                                        <div className="container text-center">
                                                            <div class="spinner mx-auto my-4">
                                                            </div>

                                                            <h4 className="text-center mt-4">¡Contacto actualizado correctamente! </h4>
                                                            <Link to="/dashboard/leads" > <Button className="my-2"> Regresar </Button> </Link>

                                                        </div>  </Popup>
                                                </Form>
                                            </div>


                                        </div>
                                    </div>




                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main >



        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {


})(Edit)  