

import Index from "components/dashboard/Index"
import HeadDash from "components/navigation/HeadDash"
import Slide from "components/navigation/Slide"
import Layout from "hocs/layouts/Layout"
import { useEffect } from 'react'
import { get_contacts, get_resume, get_opportunities,get_info } from "redux/actions/contacts/contacts";
import { get_views } from "redux/actions/views/views";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom"
import { check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import { Navigate } from "react-router-dom";


function Dashboard({
    get_contacts, contacts, count, get_views, views, get_resume, resume, get_opportunities, opportunities, get_info,information,

    login,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
    user


}) {


    useEffect(() => {
        window.scrollTo(0, 0)
        get_contacts()
        get_views()
        get_resume()
        get_opportunities()
        get_info()

        isAuthenticated ? <> </> :
            <>
                {refresh()}
                {check_authenticated()}
                {load_user()}
            </>
    }, [])
    if (isAuthenticated == false) {
        return <Navigate to='/ingresar' />
    }



    return (

        <>

            <Layout>

                {user ? <>
                    <HeadDash user={user && user} />
                </> :
                    <>loading</>
                }
                {user ? <>
                    <HeadDash user={user && user} />
                    <Index resume={resume&&resume} opportunities={opportunities&&opportunities} information= {information&&information} count={count && count} views={views && views} />
                </> :
                    <>loading</>
                }
                <Slide />


        

            </Layout>
        </>
    )
}
const mapStateToProps = state => ({
    contacts: state.contacts.contacts,
    count: state.contacts.count,
    views: state.views.views,   
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    user: state.auth.user,
    resume: state.contacts.resume,
    opportunities:state.contacts.opportunities,
    information:state.contacts.info,


})
export default connect(mapStateToProps, {
    get_contacts, get_views, get_resume, get_opportunities,    login, get_info,
    refresh,
    check_authenticated,
    load_user

})(Dashboard)