import './styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Error404 from 'containers/errors/Error404';
import Dashboard from 'containers/pages/Dashboard';
import Home from 'containers/pages/Home';
import store from './store';
import { Provider } from 'react-redux';
import Leads from 'containers/pages/Leads';
import EditLead from 'containers/pages/EditLead';
import Login from 'containers/pages/Login';

import ResetPassword from 'containers/auth/ResetPassword';
import ResetPasswordConfirm from 'containers/auth/ResetPasswordConfirm';





<style>
   @import url('https://fonts.cdnfonts.com/css/gotham');
</style>
                
function App() {



  return (
    <Provider store={store}>
      <Router>
        <Routes>
          {/*Error Display */}
          <Route path="*" element={<Error404 />} />

          {/*Home Display */}
          <Route path="/" element={<Home />} />

          {/*Dashboard  Index Display */}
          <Route path="/dashboard" element={<Dashboard />} />

          {/*Dashboard Leads Display */}
          <Route path="/dashboard/leads" element={<Leads />} />
          {/*Dashboard Leads Display */}
          <Route path="/dashboard/leads/:id" element={<EditLead/>} />

          {/*Dashboard LLogin */}
          <Route path="/ingresar" element={<Login />} />

          {/*Reset Password */}
          <Route path="/forgot_password" element={<ResetPassword />} />
          {/* confirm Reset Password */}
          <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />

        </Routes>
      </Router>
    </Provider>

  );
}

export default App;
