import { connect } from "react-redux"
import Container from 'react-bootstrap/Container';
import 'styles/card.css'
import Card from "./Card";
import { Typewriter } from "react-simple-typewriter"

import { useState, useEffect } from "react";
import 'styles/services.css'
import {motion} from "framer-motion"
import diseno from "assets/img/diseno.png"
import marketing from "assets/img/marketing.png"
import datos from "assets/img/datos.png"
import desarrollo from "assets/img/desarrollo.png"
import gestion from "assets/img/gestion.png"

function Services() {

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        function handleResize() {
            setIsSmallScreen(window.innerWidth < 768);
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <>
            <section id="services" >

                <div className="container-flex " style={{ backgroundColor: "#936BE1", marginTop: "-1px" }}>
                    <Container className="p-5 ">

                        <div className="row">
                            <div className="col-12 col-md-6">

                                <h2 className="titulos">
                                    El sector digital es { isSmallScreen? <> <br></br></> : <></>}  <span style={{ color: '#402399', fontWeight: 'bold', backgroundColor:
                                     "white" }}><Typewriter

                                        words={['Innovación', 'Futuro', 'Trasformación']}
                                        loop={15}
                                        cursor
                                        cursorStyle='_'
                                        typeSpeed={70}
                                        deleteSpeed={50}
                                        delaySpeed={1000}
                                    //onLoopDone={handleDone}
                                    //onType={handleType}
                                    />
                                    </span>
                                </h2>
                                <h3 className="titulos">¿Te gustaría ser parte?</h3>
                                <p className="text-white">Si tu respuesta es afirmativa, necesitas saber esto: Las carreras digitales están transformando la economía, el trabajo y el estudio. Actualmente son las más demandadas y las carreras mejor pagadas. Incluso puedes trabajar desde cualquier parte del mundo.  ¡Ahora elige tu carrera ideal y registrate!  </p>
                                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                                    <div className={`row my-2   ${isSmallScreen ? "remove-style" : ""}`}>
                                        <div className={`contenedor ${isSmallScreen ? "remove-style" : ""}`} style={{ paddingLeft: "0%", marginTop: "5%" }}>
                                            <Card
                                                svgicon={diseno}
                                                heading={'Diseño Gráfico'}
                                                detail={'Irás más allá, creando no solo imágenes bonitas que puedan salir de una aplicación de celular, sino verdaderos conceptos visuales que conecten a las personas con las marcas y los productos.'}
                                            ></Card>
                                        </div>
                                    </div>
                                </motion.div>

                                <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

                                    <div className={`row my-2  ${isSmallScreen ? "remove-style" : ""}`}>
                                        <div className={`contenedor ${isSmallScreen ? "remove-style" : ""}`} style={{ paddingLeft: "50%", marginTop: "-30%" }}>
                                            <Card
                                                svgicon={marketing}
                                                heading={'Marketing Digital'}
                                                detail={'Harás estudios para entender el mercado de tu empresa y a su competencia, indagarás sobre los clientes a los que quieres llegar y crearás campañas que combinen distintas formas de contacto digital.'}
                                            ></Card>
                                        </div>

                                    </div>

                                </motion.div>
                            </div>
                            <div className="col-12 col-md-6 ">

                                <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >

                                    <div className={`row my-4   ${isSmallScreen ? "remove-style" : ""}`}>
                                        <div className={`contenedor ${isSmallScreen ? "remove-style" : ""}`} style={{ paddingLeft: "50%", marginTop: "-10%" }}>
                                            <Card
                                                svgicon={gestion}
                                                heading={'Community Manager'}
                                                detail={' Te encargarás de gestionar y desarrollar comunidades online de seguidores de una marca o empresa en distintas redes sociales. '}
                                            ></Card>
                                        </div>
                                    </div>

                                </motion.div>
                                <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }} className="mt-4"  >
                                    <div className={`row my-2    ${isSmallScreen ? "remove-style" : ""}`}>
                                        <div className={`contenedor   ${isSmallScreen ? "remove-style" : ""}`} style={{ marginTop: "-5%", paddingLeft: "0%" }}>
                                            <Card
                                                svgicon={datos}
                                                heading={'Análisis de datos'}
                                                detail={'Utilizarás las fuentes de datos de tu empresa para producir información relevante que ayude a tomar decisiones de inversión y de estrategia.'}
                                            ></Card>
                                        </div>

                                    </div>
                                </motion.div>
                                <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ opacity: 1, x: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                                    <div className={`row my-2   ${isSmallScreen ? "remove-style" : ""}`}>
                                        <div className={`contenedor  ${isSmallScreen ? "remove-style" : ""}`} style={{ marginTop: "-30%", paddingLeft: "60%" }}>
                                            <Card
                                                svgicon={desarrollo}
                                                heading={'Desarrollo web Full - Stack'}
                                                detail={' Crearás aplicaciones web que se vean y funcionen tan bien como YouTube: desde donde se guardan los videos hasta el color de los botones.'}
                                            ></Card>
                                        </div>

                                    </div>
                                </motion.div>

                            </div>
                        </div>
                    </Container>
                </div >
            </section >
        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Services)