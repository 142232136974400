import {
    GET_VIEWS_SUCCESS,
    GET_VIEWS_FAIL
} from '../actions/views/types'

const initialState = {
    views: null
}

export default function views(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
       
        case GET_VIEWS_SUCCESS:
            return {
                ...state,
                views:payload.views
            }   
        case GET_VIEWS_FAIL:
            return {
                ...state,
                views: null
            }   
        default:
            return state
    }
}