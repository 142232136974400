import React, { useEffect } from 'react';

const LiveChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://botai.smartdataautomation.com/static/js/BotScript/chatbot.js';
    script.async = true;
    script.onload = () => {
      const Params = {
        BotId: 2085,
        BotVersionId: 2030,
        BotHost: 'https://botai.smartdataautomation.com',
        Initial_Step: '/reset',
        user_vars: {'CanalWeb':'PaginawebDigio'}
      };

      if (window.ChatBot) {
        new window.ChatBot(Params);
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const chatBoxStyle = {

  };

  return (
    <div id="live-chat" style={chatBoxStyle}></div>
  );
};

export default LiveChat;
