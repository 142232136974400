
import digioNav from "assets/img/digioNav.png"
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'styles/footer.css'

function HeadDash({user}) {


  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between">
          
          <img
              src={digioNav}
              width={170}
              height={45}
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            <span className="d-none d-lg-block">Admin</span>
      
          <i className="bi bi-list toggle-sidebar-btn"></i>
        </div>

        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            <li className="nav-item d-block d-lg-none">
              <a className="nav-link nav-icon search-bar-toggle " href="#">
                <i className="bi bi-search"></i>
              </a>
            </li>

           
        
            <li className="nav-item dropdown pe-3">

              <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
             
                <span className="d-none d-md-block  ps-2"> Bienvenido {user.first_name} {user.last_name}  </span>
              </a>

              
            </li>

          </ul>
        </nav>

      </header>

    </>
  )
}
const mapStateToProps = state => ({
  

})
export default connect(mapStateToProps, {

})(HeadDash)  