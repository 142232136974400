import { connect } from "react-redux"
import Container from 'react-bootstrap/Container';
import { Button } from "react-bootstrap";
import { motion } from "framer-motion"
import { useState } from "react";
import 'styles/header.css'
import vector from "assets/img/vector.png"
import ReactPlayer from 'react-player'
import PuffLoader from "react-spinners/PuffLoader"
import { Link } from "react-router-dom";



function Header() {

    const [loading] = useState(true)
    const [color] = useState("#ffffff");
 




    return (
        <>


            <section id="home" >

                <div className="container-flex pt-2 mt-2">
                    <div style={{
                        backgroundImage: `url(${vector})`,
                        backgroundSize: '100% 25%',
                        backgroundPosition: "bottom",
                        backgroundRepeat: 'no-repeat',
                    }}>

                        <Container className="p-5  ">

                            <div className="row">
                                <div className="col-12 col-md-6 ">
                                    <motion.div initial={{ rotate: 11 }} whileInView={{ rotate: 0 }} transition={{ delay: 0.1, duration: 1 }} viewport={{ once: true }}  >
                                        <h1 className="titulo">¡Hola! soy <br></br><span className="subtitulo ">DigiO</span></h1>
                                    </motion.div>
                                    <p className="mt-3" style={{ fontSize: "18px" }}>
                                        ¡Estoy aquí para ayudarte en el sector digital! Te orientaré, inspiraré e informaré sobre temas cómo saber qué estudiar,las carreras con mayor demanda en Colombia y el sector del futuro. Conmigo encontrarás todo lo necesario para tener éxito en el mundo digital.<br></br>
                                        <span style={{ color: "#936BE1", fontSize: " 20px" }}>¡</span>Déjame tus datos y recibirás información para empezar en el sector digital<span style={{ color: "#936BE1", fontSize: " 20px" }}>! </span>
                                    </p>

                                    <Button href="#contact" className="btn my-4" >
                                        <div className="row m-1" > ¡Únete!
                                            <PuffLoader className="ms-2" color={color} loading={loading} size={25} />

                                        </div>
                                    </Button>



                                </div>
                                <div className="col-12 col-md-6 p-4 my-auto ">



                                    <div className="player-wrapper rounded">
                                        <ReactPlayer url={`${"https://www.youtube.com/watch?v=Al7w1IFEI4s"}`} className="react-player" width='100%' height='275px' controls />
                                    </div>
                                </div>
                            </div>

                        </Container>
                    </div>
                </div>

            </section>
        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Header)