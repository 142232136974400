import axios from 'axios';
import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAIL,
    GET_CONTACT_SUCCESS,
    GET_CONTACT_FAIL,
    GET_RESUME_SUCCESS,
    GET_RESUME_FAIL,
    GET_FILTER_SUCCESS,
    GET_FILTER_FAIL,
    GET_OPPORTUNITIES_SUCCESS,
    GET_OPPORTUNITIES_FAIL,
    GET_INFO_SUCCESS,
    GET_INFO_FAIL,
    GET_SEARCH_BLOG_SUCCESS,
    GET_SEARCH_BLOG_FAIL,

} from './types';

export const get_contacts = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/list`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_CATEGORIES_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_CATEGORIES_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_CATEGORIES_FAIL
        })
    }
}
export const get_resume = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/resume`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_RESUME_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_RESUME_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_RESUME_FAIL
        })
    }
}
export const get_opportunities = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/opportunities`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_OPPORTUNITIES_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_OPPORTUNITIES_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_OPPORTUNITIES_FAIL
        })
    }
}
export const get_info = () => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/information`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_INFO_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_INFO_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_INFO_FAIL
        })
    }
}
export const get_filter = (s) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };
    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/search?s=${s}`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_FILTER_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_FILTER_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_FILTER_FAIL
        })
    }
}
export const get_contact = (id) => async dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/contacts/detail/${id}`, config)

        if (res.status === 200) {
            dispatch({
                type: GET_CONTACT_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_CONTACT_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_CONTACT_FAIL
        })
    }
}

export const search_blog = (search_term) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/search?s=${search_term}`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_SEARCH_BLOG_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_SEARCH_BLOG_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_SEARCH_BLOG_FAIL
        });
    }
};

export const search_blog_page = (search_term,page) => async dispatch => {

    const config = {
        headers: {
            'Accept': 'application/json'
        }
    };

    try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/search?p=${page}&s=${search_term}`, config);

        if (res.status === 200) {
            dispatch({
                type: GET_SEARCH_BLOG_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_SEARCH_BLOG_FAIL
            });
        }
    } catch (err) {
        dispatch({
            type: GET_SEARCH_BLOG_FAIL
        });
    }
};



