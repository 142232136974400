
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/style.css'
import {  useNavigate } from 'react-router-dom'
import {  logout} from 'redux/actions/auth/auth'
function Slide({logout}) {
    

    const navigate=useNavigate()

    const handleLogout=()=>{
        logout()
        navigate('/ingresar')
    }

    const urlDeseada = "/dashboard";
    const rutaActual = window.location.pathname;

    const urlLeads = "/dashboard/leads";
    const esLeads = rutaActual === urlLeads;



    const esCollapsed = rutaActual === urlDeseada;
    
    return (
        <>
            <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <a className={`nav-link ${esLeads? 'collapsed' : ''}`} href="/dashboard">
                            <i className="bi bi-grid"></i>
                            <span>Dashboard</span>
                        </a>
                    </li>

                    <li className="nav-item">
                        <a 
                        className={`nav-link ${esCollapsed ? 'collapsed' : ''}`}
                        data-bs-target="#components-nav"
                        data-bs-toggle="collapse"
                        href="/dashboard/leads">
                            <i className="bi bi-menu-button-wide"></i><span>Leads</span><i className="bi bi-chevron-down ms-auto"></i>
                        </a>
                        
                    </li>

            
                  

                    <button
                        type="button mx-auto"
                        className="my-5 btn btn-primary"
                        
                        onClick={()=>handleLogout()}
                        style={{
                            position: 'absolute',
                            bottom: '20px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                          }}
                    >
                        Logout
                    </button>
                </ul>

            </aside>

        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {
    logout

})(Slide)  