
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/style.css'
import Button from 'react-bootstrap/Button';
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import React from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Link } from "react-router-dom";
import { get_filter } from "redux/actions/contacts/contacts";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload} from "@fortawesome/free-solid-svg-icons";





function Leads({ contacts, get_filter, filtered }) {
    const [counter, setCounter] = useState(0);

    const incrementCounter = () => {
        setCounter(prevCounter => prevCounter + 1);
    };

    const [formData, setFormData] = useState({
        search: '',

    });

    const { search, } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


    //formato fecha
    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }



    const onSubmit = e => {

        e.preventDefault();
        let formData = new FormData()
        formData.append('search', search)

        let busqueda = formData.get('search')

        get_filter(busqueda);


    }

    //para
    function exportToExcel(tableData, fileName) {
        // Crear un libro de Excel
        const workbook = XLSX.utils.book_new();

        // Crear una hoja de cálculo
        const worksheet = XLSX.utils.json_to_sheet(tableData);

        // Agregar la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        // Guardar el libro de Excel como archivo
        XLSX.writeFile(workbook, fileName + '.xlsx');
    }

  


    const handleExport = () => {
        let tableData = [];
        if (filtered) {
            console.log("entra")
            tableData = filtered.filtered;
    
        }
        else {
            tableData = contacts;
    
        }
     
        exportToExcel(tableData, 'tabla_datos'); // Nombre del archivo de Excel
    };



    return (
        <>
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Leads Control</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                            <li className="breadcrumb-item active">Leads Control</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <div className="row">


                        <div className="container">

                            <div className="search-bar">
                                <Form onSubmit={e => onSubmit(e)} method="POST" className="d-inline" >
                                    <Form.Group className="mb-3 mt-3">
                                        <Form.Label className="fw-bold" style={{ color: "#5E17EB" }}>Filtro</Form.Label>
                                        <Form.Control value={search} name="search" onChange={e => onChange(e)} placeholder="Para fechas use formato YYYY-MM-DD " required />
                                    </Form.Group>
                                    <Button className="mx-2" type="submit">Filtrar</Button>
                                </Form>
                                <Button className="mx-1 d-inline " onClick={handleExport}><FontAwesomeIcon className='' icon={faDownload} color='#fff' /></Button>

                            </div>

                            <div className="row">

                                <div className="col-12">
                                    <div className="card">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="card recent-sales overflow-auto">

                                        <div className="filter">
                                            <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                <li className="dropdown-header text-start">
                                                    <h6>Filter</h6>
                                                </li>

                                                <li><a className="dropdown-item" href="#">Today</a></li>
                                                <li><a className="dropdown-item" href="#">This Month</a></li>
                                                <li><a className="dropdown-item" href="#">This Year</a></li>
                                            </ul>
                                        </div>

                                        <div className="card-body">

                                            <table id="myTable" className="table table-borderless my-2 datatable">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">id</th>
                                                        <th scope="col">Nombre</th>
                                                        <th scope="col">Género</th>
                                                        <th scope="col">Ciudad</th>
                                                        <th scope="col">Correo</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col">Interés</th>
                                                        <th scope="col">Fecha</th>
                                                        <th scope="col">Estado</th>
                                                        <th scope="col">Acciones</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filtered ? <>
                                                        {filtered.filtered.map((contact, index) => (
                                                            <tr key={contact.id} >
                                                                <td>{contact.id} </td>
                                                                <td>{contact.name} </td>
                                                                <td>{contact.gender} </td>
                                                                <td>{contact.countryCode} </td>
                                                                <td>{contact.email} </td>
                                                                <td>+57 {contact.phone} </td>
                                                                <td>{contact.interest} </td>
                                                                <td>{formatDate(contact.contact_date)}</td>
                                                                <td>{contact.status == "Oportunidad" ? <> <span className="badge bg-success">Oportunidad</span></> : <></>}
                                                                    {contact.status == "En formacion" ? <> <span className="badge bg-warning">En formación</span></> : <></>}
                                                                    {contact.status == "Sin estado" ? <> <span className="badge bg-secondary">Sin estado</span></> : <></>}</td>

                                                                <td> <Link to={`/dashboard/leads/${contact.id}`}>Editar</Link>  </td>
                                                            </tr>
                                                        ))
                                                        }


                                                    </> :
                                                        <>
                                                            {
                                                                contacts && contacts.map((contact, index) => (
                                                                    <tr key={contact.id}>
                                                                        <td>{contact.id} </td>
                                                                        <td>{contact.name} </td>
                                                                        <td>{contact.gender} </td>
                                                                        <td>{contact.countryCode} </td>
                                                                        <td>{contact.email} </td>
                                                                        <td>+57 {contact.phone} </td>
                                                                        <td>{contact.interest} </td>
                                                                        <td>{formatDate(contact.contact_date)}</td>
                                                                        <td>{contact.status == "Oportunidad" ? <> <span className="badge bg-success">Oportunidad</span></> : <></>}
                                                                            {contact.status == "En formacion" ? <> <span className="badge bg-warning">En formación</span></> : <></>}
                                                                            {contact.status == "Sin estado" ? <> <span className="badge bg-secondary">Sin estado</span></> : <></>}</td>

                                                                        <td> <Link to={`/dashboard/leads/${contact.id}`}>Editar</Link>  </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </>

                                                    }





                                                </tbody>
                                            </table>

                                        </div>   <div className="col-12">
                                            <div className="card top-selling overflow-auto">

                                                <div className="filter">
                                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                        <li className="dropdown-header text-start">
                                                            <h6>Filter</h6>
                                                        </li>

                                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                                    </ul>
                                                </div>



                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">

                                        <div className="card">
                                            <div className="filter">
                                                <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                                    <li className="dropdown-header text-start">
                                                        <h6>Filter</h6>
                                                    </li>

                                                    <li><a className="dropdown-item" href="#">Today</a></li>
                                                    <li><a className="dropdown-item" href="#">This Month</a></li>
                                                    <li><a className="dropdown-item" href="#">This Year</a></li>
                                                </ul>
                                            </div>



                                        </div>
                                    </div>


                                </div>
                            </div>



                            <div className="card">
                                <div className="filter">
                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>

                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                    </ul>
                                </div>


                            </div>
                            <div className="card">
                                <div className="filter">
                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>

                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                    </ul>
                                </div>


                            </div>
                            <div className="card">
                                <div className="filter">
                                    <a className="icon" href="#" data-bs-toggle="dropdown"><i className="bi bi-three-dots"></i></a>
                                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                        <li className="dropdown-header text-start">
                                            <h6>Filter</h6>
                                        </li>

                                        <li><a className="dropdown-item" href="#">Today</a></li>
                                        <li><a className="dropdown-item" href="#">This Month</a></li>
                                        <li><a className="dropdown-item" href="#">This Year</a></li>
                                    </ul>
                                </div>


                            </div>

                        </div>

                    </div>
                </section>
            </main >



        </>
    )
}
const mapStateToProps = state => ({
    filtered: state.contacts.filter

})
export default connect(mapStateToProps, {
    get_filter

})(Leads)  