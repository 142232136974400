import { connect } from "react-redux"
import { useEffect, useState } from "react";
import { check_authenticated, load_user, login, refresh, reset_password_confirm } from "redux/actions/auth/auth";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import digioNav from "assets/img/digioNav.png"

function ResetPasswordConfirm({
    reset_password_confirm,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
}) {

    const params = useParams()
    const uid = params.uid
    const token = params.token

    useEffect(() => {
        isAuthenticated ? <></> :
            <>
                {refresh()}
                {check_authenticated()}
                {load_user()}
            </>
    }, [])

    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });

    const {
        new_password,
        re_new_password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const navigate = useNavigate()

    const onSubmit = e => {
        e.preventDefault();
        reset_password_confirm(uid, token, new_password, re_new_password)
        navigate('/')
    }

    if (isAuthenticated) {
        return <Navigate to='/dashboard' />
    }


    return (

        <>
            <div className='container text-center'>
                <img
                    src={digioNav}
                    width={250}
                    height={125}
                    className="align-center my-2"
                    alt="React Bootstrap logo"
                />


                <form onSubmit={e => { onSubmit(e) }} className="form_main mx-auto  " action="">
                    <p className="heading">Recuperar</p>
                    <div className="inputContainer">
                
                        <input placeholder="New Password"
                            id="new_password" name='new_password'
                            className="inputField"
                            type="password"
                            required
                            value={new_password}
                            onChange={e => onChange(e)} />
                    </div>
                    <div className="inputContainer">
                     
                        <input placeholder="Repeat New Password"
                            id="re_new_password" name='re_new_password'
                            className="inputField"
                            type="password"
                            required
                            value={re_new_password}
                            onChange={e => onChange(e)} />
                    </div>


                    <button id="button" type="submit" >Cambiar Clave</button>
                </form>
            </div>

        </>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading
})

export default connect(mapStateToProps, {
    reset_password_confirm,
    refresh,
    check_authenticated,
    load_user,
})(ResetPasswordConfirm)